import React from "react"
import { getUserLanguage } from "../../utils/localizationUtils"
import LocalePageRedirect from "../../components/redirect/LocalePageRedirect"

export const IndexPage = () => {
  const [language, setLanguage] = React.useState<string | undefined>(undefined)
  React.useEffect(() => {
    setLanguage(getUserLanguage())
  }, [])
  if (!language) {
    return <></>
  }
  return <LocalePageRedirect languageId={language} />
}

export default IndexPage
